<template>
    <div id="cta" class="relative -z-10 mt-32 px-6 lg:px-8">
        <div class="mx-auto max-w-2xl text-center">
            <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Produktivitätssteigerung leicht gemacht.<br />Weniger Arbeit, mehr Zufriedenheit.</h2>
            <p class="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">Entdecken Sie, wie Homemate Ihnen bei der Immobilienverwaltung hilft und Ihren Alltag als Vermieter oder Eigentümer verbessert.</p>
            <div class="mt-10 flex items-center justify-center gap-x-6">
                <a href="#" v-scroll-to="'#hero'"
                    class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Beta-Zugang anfordern</a>
                <a href="#" class="hidden text-sm font-semibold leading-6 text-gray-900">Mehr erfahren <span
                        aria-hidden="true">→</span></a>
            </div>
        </div>
    </div>
</template>

<script>
</script>