<template>
    <div class="bg-white">

        <metainfo>
            <template v-slot:title="{ content }">{{ content }}</template>
        </metainfo>

        <!-- Header -->
        <HeaderUI />

        <main class="isolate">
            <div class="bg-white px-6 py-32 lg:px-8">
                <div class="mx-auto max-w-3xl text-base leading-7 text-gray-700">
                    <div class="blog-post-content" v-if="post">
                        <h1>{{ post.title }}</h1>
                        <div v-html="post.html"></div>
                    </div>
                </div>
            </div>
        </main>

        <!-- Footer -->
        <FooterUI />

    </div>
</template>
  
<script setup>
import axios from 'axios';
import { useRoute } from 'vue-router';
import { useMeta } from 'vue-meta';
import { onMounted, ref, watchEffect } from 'vue';

import HeaderUI from '@/components/navigation/HeaderUI.vue';
import FooterUI from '@/components/navigation/FooterUI.vue';

// Definieren Sie Ihre Metadaten hier
const meta = {
    title: 'Blog | homemate.io',
    description: '',
    keywords: 'Blog, homemate.io',
    htmlAttrs: {
        lang: 'de',
    },
};


// Use useMeta() within your setup()
useMeta(meta);

const post = ref(null);

onMounted(async () => {
    const route = useRoute();
    const routeSlug = route.params.slug;
    // Fetch the blog post based on the slug here
    // For example, using the Ghost API or your own API
    const fetchedPost = await fetchPostBySlug(routeSlug);
    post.value = fetchedPost;

    // Update the metadata after fetching the post
    updateMetaTitle();
});

async function fetchPostBySlug(slug) {
    //values of env variables are set in .env file
    const apiKey = process.env.VUE_APP_GHOST_API_KEY;
    const apiUrl = process.env.VUE_APP_GHOST_URL + `/content/posts/slug/${slug}/?key=${apiKey}`;

    try {
        const response = await axios.get(apiUrl);
        return response.data.posts[0];
    } catch (error) {
        console.error('Error fetching posts:', error);
    }
}

function updateMetaTitle() {
    if (post.value) {
        document.title = `${post.value.title} | homemate.io`;
    }
}

watchEffect(() => {
    updateMetaTitle();
});

</script>

<style>
.blog-post-content h1 {
    @apply mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl;
}

.blog-post-content h2 {
    @apply mt-16 text-2xl font-bold tracking-tight text-gray-900;
}

.blog-post-content p {
    @apply mt-6 text-base text-gray-600 leading-relaxed;
}

.blog-post-content p strong {
    @apply mt-6 text-base text-gray-900 leading-relaxed;
}

.blog-post-content a {
    @apply text-blue-500 hover:text-blue-600;
}

.blog-post-content img {
    @apply mx-auto mt-6 rounded-3xl shadow-lg;
}

.blog-post-content ul {
    @apply mt-6 list-disc list-inside;
}

.blog-post-content ul li {
    @apply my-2;
}

.blog-post-content ul strong {
    @apply text-gray-600;
}

/* Weitere Stile für andere Elemente */
</style>