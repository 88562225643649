import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/HomeView.vue";

//legal
import Imprint from "@/views/legal/ImprintView.vue";
import DataSecurity from "@/views/legal/DataSecurityView.vue";
import Terms from "@/views/legal/TermsView.vue";

//blog
import Blog from "@/views/blog/BlogListView.vue";
import BlogPost from "@/views/blog/BlogPostView.vue";

const routes = [
    {
        path: "/",
        redirect: "/de/",
    },
    {
        path: '/:lang',
        component: { template: '<router-view></router-view>' }, // Leere Wrapper-Komponente
        children: [
            {
                path: "",
                name: "Home",
                component: Home,
            },
            {
                path: "imprint",
                name: "Imprint",
                component: Imprint,
            },
            {
                path: "datasecurity",
                name: "DataSecurity",
                component: DataSecurity
            },
            {
                path: "terms",
                name: "Terms",
                component: Terms
            },
            {
                path: "blog",
                name: "Blog",
                component: Blog
            },
            {
                path: "blog/:slug",
                name: "BlogPost",
                component: BlogPost
            }
        ]
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;