<template>
    <div class="bg-white">

        <metainfo>
            <template v-slot:title="{ content }">{{ content }}</template>
        </metainfo>

        <!-- Header -->
        <HeaderUI />

        <main class="isolate">
            <BlogList />
        </main>

        <!-- Footer -->
        <FooterUI />

    </div>
</template>
  
<script setup>
import HeaderUI from '@/components/navigation/HeaderUI.vue';
import FooterUI from '@/components/navigation/FooterUI.vue';

import BlogList from '@/components/blog/BlogList.vue';

import { useMeta } from 'vue-meta';

// Definieren Sie Ihre Metadaten hier
const meta = {
    title: 'Blog | homemate.io',
    description: 'Blog',
    keywords: 'Blog, homemate.io',
    htmlAttrs: {
        lang: 'de',
    },
};

// Innerhalb Ihres setup() verwenden Sie useMeta()
useMeta(meta);

</script>