<template>
    <article
        class="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 px-8 pb-8 pt-80 sm:pt-48 lg:pt-80">
        <img v-if="post.feature_image" :src="post.feature_image" alt="" class="absolute inset-0 -z-10 h-full w-full object-cover" />
        <div class="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40" />
        <div class="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10" />

        <div class="flex flex-wrap items-center gap-y-1 overflow-hidden text-sm leading-6 text-gray-300">
            <time :datetime="post.published_at" class="mr-8">{{ formatDate(post.published_at) }}</time>
            <div class="-ml-4 flex items-center gap-x-4">
                <svg viewBox="0 0 2 2" class="-ml-0.5 h-0.5 w-0.5 flex-none fill-white/50">
                    <circle cx="1" cy="1" r="1" />
                </svg>
                <div class="flex gap-x-2.5">
                    {{ post.primary_author.name }}
                </div>
            </div>
        </div>
        <h3 class="mt-3 text-lg font-semibold leading-6 text-white">
            <a :href="'blog/' + post.slug">
                <span class="absolute inset-0" />
                {{ post.title }}
            </a>
        </h3>
    </article>
</template>
  
<script>
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        post: {
            type: Object,
            required: true,
        },
    },
    methods: {
        formatDate(date) {
            const options = { year: 'numeric', month: 'long', day: '2-digit' };
            return new Date(date).toLocaleDateString('de-DE', options);
        },
    },
});

</script>