<template>
    <div v-show="showBanner" class="flex items-center gap-x-6 bg-indigo-600 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
        <p class="text-sm leading-6 text-white">
            <a href="#">
                <strong class="font-semibold">Teste die kostenlose Beta-Version</strong><span>&nbsp;- und hilf uns, <strong>homemate</strong> noch besser zu machen! </span>
            </a>
        </p>
        <div class="flex flex-1 invisible justify-end">
            <button type="button" class="-m-3 p-3 focus-visible:outline-offset-[-4px]">
                <span class="sr-only">Dismiss</span>
                <XMarkIcon class="h-5 w-5 text-white" aria-hidden="true" />
            </button>
        </div>
    </div>
</template>
  
<script>
import { defineComponent } from 'vue';

import { XMarkIcon } from '@heroicons/vue/20/solid'

export default defineComponent({
    components: {
        XMarkIcon,
    },
    props: {
        showBanner: {
            type: Boolean,
            default: false,
        },
    },
});

</script>