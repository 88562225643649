<template>
    <div class="bg-white">

        <metainfo>
            <template v-slot:title="{ content }">{{ content }}</template>
        </metainfo>

        <!-- Header -->
        <HeaderUI />

        <main class="isolate">
            <!-- Imprint -->
            <div class="bg-white px-6 py-32 lg:px-8">
                <div class="mx-auto max-w-3xl text-base leading-7 text-gray-700">
                    <p class="text-base font-semibold leading-7 text-indigo-600">Zuletzt aktualisiert am 01.05.2023</p>
                    <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Impressum</h1>
                    <p class="mt-6 text-xl leading-8">Angaben gemäß § 5 TMG</p>
                    <p class="mt-8 text-lg font-semibold tracking-tight text-gray-900">homemate.io</p>
                    <p class="mt-2 text-base leading-7 text-gray-500">Ruthsweg 14<br>22307 Hamburg</p>
                    <!-- <p class="mt-2 text-base leading-7 text-gray-500">USt: DExxx</p> -->
                    <p class="mt-2 text-base leading-7 text-gray-500">Vertreten durch:<br>Thies Dieckmann</p>
                    <p class="mt-8 text-lg font-semibold tracking-tight text-gray-900">Kontakt</p>
                    <!-- <p class="mt-2 text-base leading-7 text-gray-500">Telefon: [Telefonnummer hier einfügen]</p> -->
                    <p class="mt-2 text-base leading-7 text-gray-500">E-Mail: <a href="mailto:hello@homemate.io"
                            class="text-indigo-600 hover:text-indigo-500 font-semibold">hello@homemate.io</a></p>
                    <!--
                            <p class="mt-8 text-lg font-semibold tracking-tight text-gray-900">Registereintrag</p>
                    <p class="mt-2 text-base leading-7 text-gray-500">Eintragung im Handelsregister.<br>Registergericht:
                        [Registergericht hier einfügen]<br>Registernummer: [Registernummer hier einfügen]</p>
                    <p class="mt-8 text-lg font-semibold tracking-tight text-gray-900">Umsatzsteuer-ID</p>
                    <p class="mt-2 text-base leading-7 text-gray-500">Umsatzsteuer-Identifikationsnummer gemäß §27 a
                        Umsatzsteuergesetz:<br>[Umsatzsteuer-ID hier einfügen]</p>
                    -->
                    <p class="mt-8 text-lg font-semibold tracking-tight text-gray-900">Verantwortlich für den Inhalt nach §
                        55 Abs. 2 RStV</p>
                    <p class="mt-2 text-base leading-7 text-gray-500">Thies Dieckmann<br>Ruthsweg 14<br>22307 Hamburg</p>
                    <div class="mt-10 max-w-2xl">
                        <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">Disclaimer</h2>
                        <p class="mt-8 text-lg font-semibold tracking-tight text-gray-900">Haftungsausschluss</p>
                        <!-- Haftung für Inhalte -->
                        <p class="mt-6 font-semibold text-gray-900">Haftung für Inhalte</p>
                        <p class="mt-6">Der Autor übernimmt keinerlei Gewähr hinsichtlich der inhaltlichen Richtigkeit,
                            Genauigkeit,
                            Aktualität, Zuverlässigkeit und Vollständigkeit der Informationen.</p>
                        <!-- Haftung für Links -->
                        <p class="mt-6 font-semibold text-gray-900">Haftung für Links</p>
                        <p class="mt-6">Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir
                            keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr
                            übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder
                            Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung
                            auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung
                            nicht erkennbar.</p>
                    </div>
                    <div class="mt-10 max-w-2xl">
                        <p class="mt-6 font-semibold text-gray-900">Urheberrecht</p>
                        <p class="mt-6">Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten
                            unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede
                            Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen
                            Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für
                            den privaten, nicht kommerziellen Gebrauch gestattet.</p>
                        <p class="mt-6">Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die
                            Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet.
                            Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
                            entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte
                            umgehend entfernen.</p>
                    </div>
                    <div class="mt-10 max-w-2xl">
                        <p class="mt-6 font-semibold text-gray-900">Datenschutz</p>
                        <p class="mt-6">Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten
                            möglich. Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder
                            E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis.
                            Diese
                            Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.</p>
                        <p class="mt-6">Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der
                            Kommunikation
                            per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff
                            durch
                            Dritte ist nicht möglich.</p>
                        <p class="mt-6">Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch
                            Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien
                            wird
                            hiermit ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich
                            rechtliche
                            Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.
                        </p>
                    </div>
                </div>
            </div>
        </main>

        <!-- Footer -->
        <FooterUI />

    </div>
</template>
  
<script setup>
import HeaderUI from '@/components/navigation/HeaderUI.vue';
import FooterUI from '@/components/navigation/FooterUI.vue';

import { useMeta } from 'vue-meta';

// Definieren Sie Ihre Metadaten hier
const meta = {
    title: 'Impressum | homemate.io',
    description: 'Impressum',
    keywords: 'Impressum, homemate.io',
    htmlAttrs: {
        lang: 'de',
    },
};

// Innerhalb Ihres setup() verwenden Sie useMeta()
useMeta(meta);

</script>