<template>
    <div class="bg-white">

        <metainfo>
            <template v-slot:title="{ content }">{{ content }}</template>
        </metainfo>

        <!-- Header -->
        <HeaderUI />

        <main class="isolate">
            <!-- Imprint -->
            <div class="bg-white px-6 py-32 lg:px-8">
                <div class="mx-auto max-w-3xl text-base leading-7 text-gray-700">
                    <p class="text-base font-semibold leading-7 text-indigo-600">Zuletzt aktulisiert am 01.05.2023</p>
                    <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Allgmeine Geschäftsbedingungen</h1>
                    <div class="mt-10 max-w-2xl">
                        <p class="mt-6">Wir freuen uns über dein Interesse an homemate.io.</p>
                    </div>
                </div>
            </div>

        </main>

        <!-- Footer -->
        <FooterUI />

    </div>
</template>
  
<script setup>
import HeaderUI from '@/components/navigation/HeaderUI.vue';
import FooterUI from '@/components/navigation/FooterUI.vue';

import { useMeta } from 'vue-meta';

// Definieren Sie Ihre Metadaten hier
const meta = {
    title: 'Datenschutzerklärung | homemate.io',
    description: 'Datenschutzerklärung',
    keywords: 'Datenschutzerklärung, Datenschutz, homemate.io',
    htmlAttrs: {
        lang: 'de',
    },
};

// Innerhalb Ihres setup() verwenden Sie useMeta()
useMeta(meta);

</script>