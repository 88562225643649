// src/plausible.js
export default function initPlausible() {
    if (process.env.VUE_APP_ENABLE_PLAUSIBLE === 'true') {
        const script = document.createElement('script');
        script.setAttribute('data-domain', 'homemate.io');
        script.setAttribute('defer', '');
        script.src = 'https://plausible.io/js/script.js';
        document.head.appendChild(script);
    }
}
