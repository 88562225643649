<template>
    <div class="bg-white">

        <metainfo>
            <template v-slot:title="{ content }">{{ content }}</template>
        </metainfo>

        <!-- Header -->
        <HeaderUI />

        <main class="isolate">
            <!-- Imprint -->
            <div class="bg-white px-6 py-32 lg:px-8">
                <div class="mx-auto max-w-3xl text-base leading-7 text-gray-700">
                    <p class="text-base font-semibold leading-7 text-indigo-600">Zuletzt aktualisiert am 01.05.2023</p>
                    <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Datenschutzerklärung</h1>
                    <p class="mt-6 text-xl leading-8">Wir nehmen den Schutz deiner persönlichen Daten sehr ernst und
                        behandeln deine personenbezogenen Daten vertraulich und entsprechend der gesetzlichen
                        Datenschutzvorschriften sowie dieser Datenschutzerklärung.</p>
                    <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">Verantwortliche Stelle</h2>
                    <p class="mt-6 text-base leading-7 text-gray-500">Thies Dieckmann<br>Ruthsweg 14<br>22307 Hamburg</p>
                    <p class="mt-6">E-Mail: <a href="mailto:hello@homemate.io"
                            class="text-indigo-600 hover:text-indigo-500 font-semibold">hello@homemate.io</a></p>
                    <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">Erhebung und Verarbeitung von Daten
                    </h2>
                    <p class="mt-6">Jeder Zugriff auf unsere Website und jeder Abruf einer auf der Website hinterlegten
                        Datei werden protokolliert. Die Speicherung dient internen systembezogenen und statistischen
                        Zwecken. Protokolliert werden: Name der abgerufenen Datei, Datum und Uhrzeit des Abrufs, übertragene
                        Datenmenge, Meldung über erfolgreichen Abruf, Webbrowser und anfragende Domain.</p>
                    <p class="mt-6">Zusätzlich werden die IP Adressen der anfragenden Rechner protokolliert.</p>
                    <p class="mt-6">Weitergehende personenbezogene Daten werden nur erfasst, wenn du diese Angaben
                        freiwillig, etwa im Rahmen einer Anfrage oder Registrierung, machst.</p>
                    <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">Nutzung und Weitergabe
                        personenbezogener Daten</h2>
                    <p class="mt-6">Soweit du uns personenbezogene Daten zur Verfügung gestellt hast, verwenden wir diese
                        nur zur Beantwortung deiner Anfragen, zur Abwicklung mit dir geschlossener Verträge und für die
                        technische Administration.</p>
                    <p class="mt-6">Deine personenbezogenen Daten werden an Dritte nur weitergegeben oder sonst übermittelt,
                        wenn dies zum Zwecke der Vertragsabwicklung – insbesondere Weitergabe von Bestelldaten an
                        Lieferanten – erforderlich ist, dies zu Abrechnungszwecken erforderlich ist oder du zuvor
                        eingewilligt hast. Du hast das Recht, eine erteilte Einwilligung mit Wirkung für die Zukunft
                        jederzeit zu widerrufen.</p>
                    <p class="mt-6">Die Löschung der gespeicherten personenbezogenen Daten erfolgt, wenn du deine
                        Einwilligung zur Speicherung widerrufst, wenn ihre Kenntnis zur Erfüllung des mit der Speicherung
                        verfolgten Zwecks nicht mehr erforderlich ist oder wenn ihre Speicherung aus sonstigen gesetzlichen
                        Gründen unzulässig ist.</p>
                    <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">Auskunftsrecht</h2>
                    <p class="mt-6">Auf schriftliche Anfrage werden wir dich gern über die zu deiner Person gespeicherten
                        Daten informieren.</p>
                    <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">Sicherheitshinweis</h2>
                    <p class="mt-6">Wir sind bemüht, deine personenbezogenen Daten durch Ergreifung aller technischen und
                        organisatorischen Möglichkeiten so zu speichern, dass sie für Dritte nicht zugänglich sind. Bei der
                        Kommunikation per E-Mail kann die vollständige Datensicherheit von uns nicht gewährleistet werden,
                        so dass wir dir bei vertraulichen Informationen den Postweg empfehlen.</p>
                    <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">Cookies</h2>
                    <p class="mt-6">Die Internetseiten verwenden an mehreren Stellen sogenannte Cookies. Sie dienen dazu,
                        unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine
                        Textdateien, die auf deinem Rechner abgelegt werden und die dein Browser speichert. Die meisten der
                        von uns verwendeten Cookies sind sogenannte „Session-Cookies“. Sie werden nach Ende deines Besuchs
                        automatisch gelöscht. Cookies richten auf deinem Rechner keinen Schaden an und enthalten keine
                        Viren.</p>
                    <!--
                    <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">Google Analytics</h2>
                    <p class="mt-6">Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc.
                        ("Google"). Google Analytics verwendet sog. "Cookies", Textdateien, die auf deinem Computer
                        gespeichert werden und die eine Analyse der Benutzung der Website durch dich ermöglichen. Die durch
                        den Cookie erzeugten Informationen über deine Benutzung dieser Website (einschließlich deiner
                        IP-Adresse) wird an einen Server von Google in den USA übertragen und dort gespeichert. Google wird
                        diese Informationen benutzen, um deine Nutzung der Website auszuwerten, um Reports über die
                        Websiteaktivitäten für die Websitebetreiber zusammenzustellen und um weitere mit der Websitenutzung
                        und der Internetnutzung verbundene Dienstleistungen zu erbringen. Auch wird Google diese
                        Informationen gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben ist oder
                        soweit Dritte diese Daten im Auftrag von Google verarbeiten. Google wird in keinem Fall deine
                        IP-Adresse mit anderen Daten von Google in Verbindung bringen. Du kannst die Installation der
                        Cookies durch eine entsprechende Einstellung deiner Browser Software verhindern; wir weisen dich
                        jedoch darauf hin, dass du in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website
                        vollumfänglich nutzen kannst. Durch die Nutzung dieser Website erklärst du dich mit der Bearbeitung
                        der über dich erhobenen Daten durch Google in der zuvor beschriebenen Art und Weise und zu dem zuvor
                        benannten Zweck einverstanden.</p>
                    -->
                    <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">Änderung unserer
                        Datenschutzbestimmungen</h2>
                    <p class="mt-6">Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den
                        aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der
                        Datenschutzerklärung umzusetzen, z.B. bei der Einführung neuer Services. Für deinen erneuten Besuch
                        gilt dann die neue Datenschutzerklärung.</p>
                    <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">Fragen an den Datenschutzbeauftragten
                    </h2>
                    <p class="mt-6">Wenn du Fragen zum Datenschutz hast, schreibe uns bitte eine E-Mail oder wende dich
                        direkt an die für den Datenschutz verantwortliche Person in unserer Organisation:</p>
                    <p class="mt-2 text-base leading-7 text-gray-500">Thies Dieckmann<br>Ruthsweg 14<br>22307 Hamburg</p>
                    <p class="mt-2 text-base leading-7 text-gray-500">E-Mail: <a href="mailto:hello@homemate.io"
                            class="text-indigo-600 hover:text-indigo-500 font-semibold">hello@homemate.io</a></p>
                </div>
            </div>



        </main>

        <!-- Footer -->
        <FooterUI />

    </div>
</template>
  
<script setup>
import HeaderUI from '@/components/navigation/HeaderUI.vue';
import FooterUI from '@/components/navigation/FooterUI.vue';

import { useMeta } from 'vue-meta';

// Definieren Sie Ihre Metadaten hier
const meta = {
    title: 'Datenschutzerklärung | homemate.io',
    description: 'Datenschutzerklärung',
    keywords: 'Datenschutzerklärung, Datenschutz, homemate.io',
    htmlAttrs: {
        lang: 'de',
    },
};

// Innerhalb Ihres setup() verwenden Sie useMeta()
useMeta(meta);

</script>