import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import VueScrollTo from 'vue-scrollto';

//import vue-meta
import { createMetaManager } from 'vue-meta';
const metaManager = createMetaManager();

//import css
import './assets/styles/main.css'

// import and run Plausible script
import initPlausible from './utils/plausible';
initPlausible();

import VueRoughNotation from 'vue-rough-notation';

createApp(App).use(metaManager).use(router).use(VueScrollTo).use(VueRoughNotation).mount('#app')
