<template>
    <div id="features" class="mx-auto py-24 sm:py-32 max-w-7xl px-6 lg:px-8">
        <div class="mx-auto max-w-2xl lg:text-center">
            <h2 class="text-base font-semibold leading-7 text-indigo-600">Funktionen</h2>
            <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Alles, was Sie benötigen, um Ihre Immobilien effektiv zu verwalten</p>
            <p class="mt-6 text-lg leading-8 text-gray-600">Entdecken Sie Homemate, die benutzerfreundliche Plattform für Vermieter und Eigentümer, die speziell entwickelt wurde, um Ihre Immobilienverwaltung zu optimieren und Ihre Produktivität zu steigern.</p>
        </div>
        <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
            <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                <div v-for="feature in features" :key="feature.name" class="relative pl-16">
                    <dt class="text-base font-semibold leading-7 text-gray-900">
                        <div
                            class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                            <component :is="feature.icon" class="h-6 w-6 text-white" aria-hidden="true" />
                        </div>
                        {{ feature.name }}
                    </dt>
                    <dd class="mt-2 text-base leading-7 text-gray-600">{{ feature.description }}</dd>
                </div>
            </dl>
        </div>
    </div>
</template>

<script setup>
import {
    ArrowPathIcon,
    CloudArrowUpIcon,
    FingerPrintIcon,
    LockClosedIcon,
} from '@heroicons/vue/24/outline'

const features = [
    {
        name: 'Dashboard',
        description:
            'Zentrale Übersicht für eine effiziente Immobilienverwaltung. Alle wichtigen Informationen auf einen Blick.',
        icon: CloudArrowUpIcon,
    },
    {
        name: 'Neuvermietung',
        description:
            'Effiziente Neuvermietung durch Digitalisierung aller Prozesse. Von der Anzeigenschaltung bis zur Vertragsunterzeichnung.',
        icon: LockClosedIcon,
    },
    {
        name: 'Finanzen',
        description:
            'Finanzübersicht für effiziente Mietverwaltung, Buchhaltung und Budgetkontrolle. Cashflow, Einnahmen und Ausgaben im Blick.',
        icon: ArrowPathIcon,
    },
    {
        name: 'Kommunikation',
        description:
            'Mieter- und Dienstleisterkommunikation auf einer zentralen Plattform.',
        icon: FingerPrintIcon,
    },
    {
        name: 'Integrationen',
        description:
            'Erweitern Sie Ihre Mietverwaltung mit nützlichen Integrationen. Von der Buchhaltung bis zur Handwerkersuche',
        icon: CloudArrowUpIcon,
    },
    {
        name: 'Mietautomatisierung',
        description:
            'Zeit sparen durch intelligente Automatisierung. Von der Mieteingangskontrolle bis zur Mietvertragsverlängerung.',
        icon: LockClosedIcon,
    }
]
</script>