<template>
    <div v-show="!cookiesConsent"
        class="fixed inset-x-0 bottom-0 flex flex-col justify-between gap-x-8 gap-y-4 bg-white p-6 ring-1 ring-gray-900/10 md:flex-row md:items-center lg:px-8">
        <FingerPrintIcon class="h-8 w-8 text-gray-900" aria-hidden="true" />
        <p class="max-w-4xl text-sm leading-6 text-gray-900">Wir verwenden Cookies, um Ihnen die bestmögliche
            Nutzererfahrung auf unserer Website zu bieten, die Leistung zu optimieren und Ihnen relevante Inhalte
            bereitzustellen. Siehe unsere <a href="/de/datasecurity"
                class="font-semibold text-indigo-600">Datenschutzerklärung</a>.</p>
        <div class="flex flex-none items-center gap-x-5">
            <button type="button" @click="acceptCookies"
                class="rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900">
                Bestätigen
            </button>
            <button type="button" @click="declineCookies" class="text-sm font-semibold leading-6 text-gray-900">Alle
                ablehnen</button>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';

import { FingerPrintIcon } from '@heroicons/vue/24/outline';

const cookiesConsent = ref(localStorage.getItem('cookiesConsent') === 'true');
const cookiesAccepted = ref(localStorage.getItem('cookiesAccepted') === 'true');

const acceptCookies = () => {
    localStorage.setItem('cookiesConsent', 'true');
    localStorage.setItem('cookiesAccepted', 'true');
    cookiesConsent.value = true;
    cookiesAccepted.value = true;
};

const declineCookies = () => {
    localStorage.setItem('cookiesConsent', 'true');
    localStorage.setItem('cookiesAccepted', 'false');
    cookiesConsent.value = true;
    cookiesAccepted.value = false;
};
</script>