<template>
    <div id="hero" class="relative pt-14">
        <div class="py-24 sm:py-32">
            <div class="mx-auto max-w-7xl px-6 lg:px-8">
                <div class="mx-auto max-w-2xl text-center">
                    <h1 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-6xl">Stressfreie Immobilienverwaltung
                    </h1>
                    <p class="mt-6 text-lg leading-8 text-gray-600">Mit <span class="underline-homemate">homemate
                            <svg viewBox="0 0 90 10" xmlns="http://www.w3.org/2000/svg">
                                <path id="pathItem" d="M3 7 Q 20 4 85 7" stroke="rgba(79, 70, 229, 0.8)" fill="transparent" stroke-width="4" stroke-linecap="round" data-v-3aa78a1a=""></path>
                            </svg>
                        </span>
                        kannst du deine Immobilien einfach online
                        verwalten und so deine Effizienz steigern und deinen Aufwand minimieren.</p>
                    <div class="mt-10 flex items-center justify-center gap-x-6">
                        <div v-if="!success" class="flex flex-col md:flex-row gap-x-4 gap-y-4">
                            <div class="relative w-64 md:w-60">
                                <input v-model="email" id="email" name="email" type="email" autocomplete="email" required=""
                                    class="min-w-0 w-full flex-auto appearance-none rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    :class="{ 'border-2 border-red-300': showError }" placeholder="E-Mail eingeben" />
                                <p v-if="showError" class="relative md:absolute left-0 mt-2 text-sm text-red-600"
                                    id="email-error">Fehler bei Anfrage aufgetreten.</p>
                            </div>

                            <button @click="requestBetaAccess" type="submit"
                                class="flex-none rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                :disabled="loading || !email">
                                <span v-if="!loading">Gratis Beta-Zugang anfordern</span>
                                <template v-else>
                                    <svg class="animate-spin h-5 w-5 text-white" fill="none" viewBox="0 0 24 24">
                                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                            stroke-width="4"></circle>
                                        <path class="opacity-75" fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                                        </path>
                                    </svg>
                                </template>
                            </button>
                        </div>
                        <div v-if="success" class="mt-4">
                            <div class="flex items-center space-x-2">
                                <CheckCircleIcon class="h-6 w-6 text-green-500" aria-hidden="true" />
                                <span class="text-green-600 font-semibold">Beta-Zugang wurde erfolgreich angefordert</span>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="mt-16 flow-root sm:mt-24">
                    <div
                        class="-m-2 rounded-xl bg-gray-900/5 p-2 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4">
                        <img src="https://tailwindui.com/img/component-images/project-app-screenshot.png"
                            alt="App screenshot" width="2432" height="1442"
                            class="rounded-md shadow-2xl ring-1 ring-gray-900/10" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import { CheckCircleIcon } from '@heroicons/vue/20/solid'

export default {
    components: {
        CheckCircleIcon
    },
    setup() {
        const email = ref('');
        const loading = ref(false);
        const success = ref(false);
        const showError = ref(false);

        const requestBetaAccess = async () => {
            loading.value = true;
            showError.value = false;

            try {
                const response = await fetch(`${process.env.VUE_APP_API_URL}/auth/signup-beta`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email: email.value }),
                });

                if (response.status === 201) {
                    success.value = true;
                } else {
                    showError.value = true;
                }
            } catch (error) {
                showError.value = true;
            } finally {
                loading.value = false;
            }
        };

        return {
            email,
            loading,
            success,
            showError,
            requestBetaAccess,
        };
    }
};
</script>

<style scoped>
.underline-homemate {
    position: relative;
    display: inline;
    font-weight: 700;
}

.underline-homemate svg {
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
}
</style>


