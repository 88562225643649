<template>
  <div id="price" class="bg-white py-24 sm:py-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-4xl text-center">
        <h2 class="text-base font-semibold leading-7 text-indigo-600">Preise</h2>
        <p class="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">Preispläne für alle Immobilienbesitzer
          geeignet</p>
      </div>
      <p class="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">Wählen Sie einen erschwinglichen Plan
        mit den besten Funktionen, um Ihr Publikum zu binden, Kundenbindung aufzubauen und den Verkauf zu steigern.</p>
      <div class="mt-16 flex justify-center">
        <RadioGroup v-model="frequency"
          class="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200">
          <RadioGroupLabel class="sr-only">Payment frequency</RadioGroupLabel>
          <RadioGroupOption as="template" v-for="option in frequencies" :key="option.value" :value="option"
            v-slot="{ checked }">
            <div
              :class="[checked ? 'bg-indigo-600 text-white' : 'text-gray-500', 'cursor-pointer rounded-full px-2.5 py-1']">
              <span>{{ option.label }}</span>
            </div>
          </RadioGroupOption>
        </RadioGroup>
      </div>
      <div class="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
        <div v-for="tier in tiers" :key="tier.id"
          :class="[tier.mostPopular ? 'ring-2 ring-indigo-600' : 'ring-1 ring-gray-200', 'rounded-3xl p-8 xl:p-10']">
          <div class="flex items-center justify-between gap-x-4">
            <h3 :id="tier.id"
              :class="[tier.mostPopular ? 'text-indigo-600' : 'text-gray-900', 'text-lg font-semibold leading-8']">{{
                tier.name }}</h3>
            <p v-if="tier.mostPopular"
              class="rounded-full bg-indigo-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-indigo-600">Most
              popular</p>
          </div>
          <p class="mt-4 text-sm leading-6 text-gray-600">{{ tier.description }}</p>

          <p class="mt-6 flex items-baseline gap-x-1">
            <RoughNotation type="strike-through" color="red" is-show="true" multiline="true" strokeWidth="4" iterations="4" padding="30">
              <span class="text-4xl font-bold tracking-tight text-gray-900">{{ tier.price[frequency.value] }}</span>
            </RoughNotation>
            <span class="text-sm font-semibold leading-6 text-gray-600">{{ frequency.priceSuffix }}</span>
          </p>

          <a :href="tier.href" v-scroll-to="'#hero'" :aria-describedby="tier.id"
            :class="[tier.mostPopular ? 'bg-indigo-600 text-white shadow-sm hover:bg-indigo-500' : 'text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300', 'mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600']">Gratis
            Beta-Zugang anfordern</a>
          <ul role="list" class="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10">
            <li v-for="feature in tier.features" :key="feature" class="flex gap-x-3">
              <CheckIcon class="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
              {{ feature }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script setup>
import { ref } from 'vue'
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'
import { CheckIcon } from '@heroicons/vue/20/solid'

const frequencies = [
  { value: 'monthly', label: 'Monatlich', priceSuffix: '/Monat' },
  { value: 'annually', label: 'Jährlich', priceSuffix: '/Jahr' },
]
const tiers = [
  {
    name: 'Basic',
    id: 'tier-freelancer',
    href: '#',
    price: { monthly: '€9', annually: '€90' },
    description: 'Der ideale Start in die Immobilienwelt',
    features: ['Bis zu 2 Immobilien', 'Basis-Analyse', '48-Stunden-Antwortzeit'],
    mostPopular: false,
  },
  {
    name: 'Business',
    id: 'tier-startup',
    href: '#',
    price: { monthly: '€39', annually: '€390' },
    description: 'Für erfahrene Immobilienprofis, die mehr erreichen möchten',
    features: [
      'Bis zu 10 Immobilien',
      'Erweiterte Analyse',
      '24-Stunden-Antwortzeit',
      'Automatisierte Workflows',
    ],
    mostPopular: true,
  },
  {
    name: 'Pro',
    id: 'tier-enterprise',
    href: '#',
    price: { monthly: '€69', annually: '€690' },
    description: 'Die ultimative Lösung für anspruchsvolle Immobilienbedürfnisse',
    features: [
      'Unbegrenzte Immobilien',
      'Erweiterte Analyse',
      'Advanced analytics',
      '1-Stunde, dedizierte Antwortzeit',
      'Automatisierte Workflows',
      'Individuelle Berichterstattung',
    ],
    mostPopular: false,
  },
]

const frequency = ref(frequencies[0])
</script>

<style>
.strike-through {
  position: relative;
}

.strike-line {
  position: absolute;
  height: 1px;
  width: 100%;
  background-color: #000;
  top: 50%;
  left: 0;
}
</style>