<template>
    <div class="bg-white">
        <div class="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
            <div class="mx-auto max-w-4xl divide-y divide-gray-900/10">
                <h2 class="text-2xl font-bold leading-10 tracking-tight text-gray-900">Häufig gestellte Fragen</h2>
                <dl class="mt-10 space-y-6 divide-y divide-gray-900/10">
                    <Disclosure as="div" v-for="faq in faqs" :key="faq.question" class="pt-6" v-slot="{ open }">
                        <dt>
                            <DisclosureButton class="flex w-full items-start justify-between text-left text-gray-900">
                                <span class="text-base font-semibold leading-7">{{ faq.question }}</span>
                                <span class="ml-6 flex h-7 items-center">
                                    <PlusSmallIcon v-if="!open" class="h-6 w-6" aria-hidden="true" />
                                    <MinusSmallIcon v-else class="h-6 w-6" aria-hidden="true" />
                                </span>
                            </DisclosureButton>
                        </dt>
                        <DisclosurePanel as="dd" class="mt-2 pr-12">
                            <p class="text-base leading-7 text-gray-600">{{ faq.answer }}</p>
                        </DisclosurePanel>
                    </Disclosure>
                </dl>
            </div>
        </div>
    </div>
</template>
  
<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/vue/24/outline'

const faqs = [
    {
        id: 1,
        question: "Was ist eine Mietverwaltungssoftware?",
        answer:
            "Eine Mietverwaltungssoftware ist eine digitale Lösung, die Vermietern und Wohnungseigentümern hilft, ihre Immobilien effizient und zeitsparend zu verwalten. Sie umfasst Funktionen wie Mietermanagement, Finanzverwaltung, Neuvermietung, Kommunikation und Automatisierung von Prozessen."
    },
    {
        id: 2,
        question: "Kann ich mit Ihrer Software Mietverträge erstellen?",
        answer:
            "Ja, unsere Mietverwaltungssoftware ermöglicht Ihnen, rechtssichere Mietverträge mit wenigen Klicks zu erstellen. Sie können auf Vorlagen zurückgreifen und diese an Ihre individuellen Bedürfnisse anpassen."
    },
    {
        id: 3,
        question: "Wie kann ich die Kommunikation mit meinen Mietern verbessern?",
        answer:
            "Unsere Software bietet Ihnen eine zentrale Kommunikationsplattform, um den Austausch mit Ihren Mietern zu vereinfachen. Sie können Nachrichten senden, wichtige Dokumente teilen und Anfragen von Mietern effizient verwalten."
    },
    {
        id: 4,
        question: "Wie behalte ich meine Immobilienfinanzen im Blick?",
        answer:
            "Unsere Mietverwaltungssoftware bietet Ihnen eine umfassende Finanzübersicht, die es Ihnen ermöglicht, Ihre Einnahmen, Ausgaben und Mietzahlungen zu verfolgen. Sie können Berichte erstellen, um Ihre Immobilienfinanzen besser zu verstehen und zu optimieren."
    },
    {
        id: 5,
        question: "Wie kann ich meine Immobilienverwaltung automatisieren?",
        answer:
            "Unsere Software bietet Mietautomatisierungsfunktionen, mit denen Sie wiederkehrende Aufgaben automatisieren und Zeit sparen können. Dazu gehören Mietinkasso, Erinnerungen an fällige Zahlungen, Mieterkommunikation und vieles mehr."
    },
    {
        id: 6,
        question: "Wie funktioniert der Neuvermietungsprozess mit Ihrer Software?",
        answer:
            "Unsere Software vereinfacht den Neuvermietungsprozess, indem sie Ihnen ermöglicht, Wohnungsanzeigen online zu erstellen, Mietinteressenten zu verwalten, Besichtigungstermine zu koordinieren, Mieterselbstauskünfte einzuholen und Mietverträge digital abzuschließen."
    },
    {
        id: 7,
        question: "Kann ich Ihre Software mit anderen Tools integrieren?",
        answer:
            "Ja, unsere Mietverwaltungssoftware ermöglicht es Ihnen, sich mit verschiedenen Drittanbieter-Tools zu verbinden, um Ihre Arbeitsabläufe zu optimieren und Ihren Bedürfnissen gerecht zu werden."
    },
    {
        id: 8,
        question: "Ist Ihre Mietverwaltungssoftware für kleine und große Immobilienportfolios geeignet?",
        answer:
            "Ja, unsere Software ist sowohl für kleine als auch für große Immobilienportfolios geeignet und bietet skalierbare Funktionen, die sich an die Größe Ihres Portfolios anpassen."
    },
    {
        id: 9,
        question: "Wie sicher sind meine Daten in Ihrer Mietverwaltungssoftware?",
        answer:
            "Die Sicherheit Ihrer Daten hat für uns höchste Priorität. Wir nutzen modernste Sicherheitstechnologien und -protokolle, um Ihre Daten zu schützen und regelmäßige Backups durchzuführen."
    },
    {
        id: 10,
        question: "Wie kann ich Ihre Software ausprobieren?",
        answer:
            "Um unsere Mietverwaltungssoftware auszuprobieren, können Sie sich für eine kostenlose Testversion anmelden, die Ihnen Zugang zu allen Funktionen ermöglicht. Nach Ablauf der Testphase können Sie entscheiden, ob Sie ein Abonnement abschließen möchten."
    },
    {
        id: 11,
        question: "Welche Unterstützung bieten Sie bei der Nutzung Ihrer Mietverwaltungssoftware?",
        answer:
            "Wir bieten umfassenden Support für unsere Kunden, einschließlich einer Wissensdatenbank, Video-Tutorials, Live-Chat und E-Mail-Support. Unser Kundendienst ist bestrebt, Ihnen bei Fragen oder Problemen schnell und effizient zu helfen."
    },
    {
        id: 12,
        question: "Kann ich mit Ihrer Software auch Mietkautionen verwalten?",
        answer:
            "Ja, unsere Mietverwaltungssoftware bietet Ihnen die Möglichkeit, Mietkautionen einfach und effizient zu verwalten. Sie können Kautionen erfassen, hinterlegen und bei Bedarf zurückzahlen."
    },
    {
        id: 13,
        question: "Wie hilft Ihre Software bei der Erstellung von Übergabeprotokollen?",
        answer:
            "Unsere Software bietet Ihnen ein Übergabeprotokoll-Tool, das Ihnen hilft, detaillierte und rechtssichere Übergabeprotokolle für Ihre Immobilien zu erstellen. Sie können Fotos hinzufügen, Beschreibungen erfassen und das Protokoll digital unterschreiben lassen."
    },
    {
        id: 14,
        question: "Kann ich meine bestehenden Mieterdaten in Ihre Software importieren?",
        answer:
            "Ja, Sie können Ihre bestehenden Mieterdaten einfach und sicher in unsere Mietverwaltungssoftware importieren. Wir bieten Unterstützung bei der Datenmigration, um Ihnen den Umstieg so einfach wie möglich zu gestalten."
    },
    {
        id: 15,
        question: "Bietet Ihre Software auch Funktionen zur Instandhaltung und Reparaturverwaltung?",
        answer:
            "Ja, unsere Mietverwaltungssoftware ermöglicht es Ihnen, Instandhaltungs- und Reparaturanfragen von Mietern zu verwalten, Dienstleister zu beauftragen und den Fortschritt von Arbeiten zu überwachen."
    },
    {
        id: 16,
        question: "Wie können Mieter die Software nutzen?",
        answer:
            "Mieter können auf einen Mieterbereich zugreifen, in dem sie Mietvertragsdetails einsehen, Dokumente herunterladen, Zahlungen tätigen und mit dem Vermieter kommunizieren können. Dieser Zugang erleichtert die Kommunikation und fördert Transparenz."
    },
    {
        id: 17,
        question: "Welche Zahlungsmethoden unterstützt Ihre Mietverwaltungssoftware?",
        answer:
            "Unsere Software unterstützt verschiedene Zahlungsmethoden wie Überweisungen, Lastschriften und Online-Zahlungsdienste. Sie können die für Ihre Mieter und Ihr Unternehmen am besten geeignete Methode wählen."
    },
    {
        id: 18,
        question: "Kann ich mit Ihrer Software auch Nebenkostenabrechnungen erstellen?",
        answer:
            "Ja, unsere Mietverwaltungssoftware bietet Ihnen die Möglichkeit, Nebenkostenabrechnungen einfach und präzise zu erstellen. Sie können Verbrauchsdaten erfassen, Kosten verteilen und automatisierte Abrechnungen für Ihre Mieter generieren."
    },
    {
        id: 19,
        question: "Ist Ihre Mietverwaltungssoftware für gewerbliche und private Immobilien geeignet?",
        answer:
            "Unsere Software ist sowohl für gewerbliche als auch für private Immobilien geeignet und bietet spezifische Funktionen für die jeweiligen Anforderungen. Sie können Ihre Immobilienarten entsprechend verwalten und die Vorteile unserer umfassenden Lösung nutzen."
    },
    {
        id: 20,
        question: "Wie kann ich meine Wohnungsanzeigen online veröffentlichen?",
        answer:
            "Mit unserer Mietverwaltungssoftware können Sie professionelle Wohnungsanzeigen erstellen und sie direkt auf gängigen Immobilienportalen veröffentlichen. Sie können Fotos, Beschreibungen und Besichtigungstermine hinzufügen, um potenzielle Mieter anzulocken."
    }
]
</script>