<template>
    <div class="bg-white">

        <metainfo>
            <template v-slot:title="{ content }">{{ content }}</template>
        </metainfo>

        <!-- Header -->
        <HeaderUI />

        <main class="isolate">
            <!-- Hero section -->
            <HeroSection />

            <!-- Logo cloud -->

            <!-- Feature section -->
            <FeatureSection />

            <!-- Testimonial section -->

            <!-- Pricing section -->
            <PricingSection />

            <!-- FAQs section -->
            <FaqSection />

            <!-- Blog section -->
            <BlogSection />

            <!-- CTA section -->
            <CtaSection />

        </main>

        <!-- Footer -->
        <FooterUI />

    </div>
</template>
  
<script setup>
import HeaderUI from '@/components/navigation/HeaderUI.vue';
import FooterUI from '@/components/navigation/FooterUI.vue';

//Sections
import HeroSection from '@/components/sections/HeroSection.vue';
import FeatureSection from '@/components/sections/FeatureSection.vue';
import PricingSection from '@/components/sections/PricingSection.vue';
import FaqSection from '@/components/sections/FaqSection.vue';
import BlogSection from '@/components/sections/BlogSection.vue';
import CtaSection from '@/components/sections/CtaSection.vue';

import { useMeta } from 'vue-meta';

// Definieren Sie Ihre Metadaten hier
const meta = {
    title: 'Stressfreie Immobilienverwaltung - Immobilien einfach online verwalten | homemate.io',
    description: 'Entdecke homemate – die Immobilienverwaltungsplattform, die deinen Alltag als Wohnungseigentümer vereinfacht. Verwalte deine Immobilien online und steigere deine Effizienz mit unseren leistungsstarken Mietverwaltungstools.',
    keywords: 'Immobilienverwaltung, Immobilien, Verwaltung, Online, Software, Vermietung, Mietverwaltung, Zeitersparnis, Eigentümer-Tools',
    htmlAttrs: {
        lang: 'de',
    },
    link: [
        {
            rel: 'canonical',
            href: 'https://homemate.io/',
        },
    ],
};

// Innerhalb Ihres setup() verwenden Sie useMeta()
useMeta(meta);

</script>