<template>
    <div id="blog" class="bg-white py-24 sm:py-32">
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
            <div class="mx-auto max-w-2xl text-center">
                <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Blog</h2>
                <p class="mt-2 text-lg leading-8 text-gray-600">Erfahren Sie mehr über die neusten Funktionen, Success
                    Stories und erhalten Sie Einblicke in Immobilienverwaltung: Experten-Tipps, Trends und wertvolle
                    Erfahrungsberichte.</p>
            </div>
            <div
                class="mx-auto mt-16 grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                <BlogPost v-for="post in posts" :key="post.id" :post="post" />
            </div>
        </div>
    </div>
</template>

<script setup>
import axios from 'axios';
import { ref, onMounted } from 'vue';
import BlogPost from "@/components/blog/BlogPost.vue";

const posts = ref([]);

async function fetchPosts() {
    const apiKey = '8c1cc0da67ac08ea5ced9e5ee1';
    const apiUrl = 'https://homemate.ghost.io/ghost/api/v3/content/posts/?include=authors&key=' + apiKey + '&limit=3';

    try {
        const response = await axios.get(apiUrl);
        posts.value = response.data.posts;
    } catch (error) {
        console.error('Error fetching posts:', error);
    }
}

onMounted(() => {
    fetchPosts();
});
</script>